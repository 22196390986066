import * as React from "react";
import { Link, Head } from "@inertiajs/react";
import { Course, PageProps } from "@/types";
import { Button } from "@/Components/ui/Button";
import { t } from "i18next";
import DefaultLayout from "@/Layouts/DefaultLayout";
import LandingCoursesCarousel from "@/Components/Landing/LandingCourseCarousel";
import {
    LandingCertificate,
    LandingExperts,
    LandingShortCourses,
    GreenEllipse,
    PinkEllipse,
    BlueEllipse,
} from "@/Components/ui/Icons";
import { Accordion } from "@/Components/ui/Accordion";
import LandingFAQ from "@/Components/Landing/LandingFAQ";
import LandingFaqCarousel from "@/Components/Landing/LandingFaqCarousel";
import { motion } from "framer-motion";

export default function Welcome({
    auth,
    laravelVersion,
    phpVersion,
    popularCourses,
}: PageProps<{
    laravelVersion: string;
    phpVersion: string;
    popularCourses: Course[];
}>) {
    const [activeItem, setActiveItem] = React.useState<string | null>(null);
    const handleToggle = (value: string) => {
        setActiveItem((prev) => (prev === value ? null : value));
    };

    const sectionVariants = {
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <>
            <Head title="Welcome" />
            <DefaultLayout>
                <div className="flex flex-col gap-16 lg:gap-24">
                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="flex flex-col-reverse justify-between lg:flex-row lg:px-16">
                            <div className="flex flex-col w-full gap-8 lg:w-1/2 lg:mt-32">
                                <p className="text-3xl font-semibold whitespace-pre-line lg:leading-relaxed lg:text-4xl">
                                    {t("Landing.getFreeCoursesAndLecturesDesc")}
                                </p>
                                <div className="flex gap-2 justify-evenly lg:justify-start">
                                    <Button
                                        asChild
                                        variant={"gradientBlue"}
                                        className="w-full h-11 lg:w-44"
                                    >
                                        <Link href={"/register"}>
                                            {t("auth.joinNow")}
                                        </Link>
                                    </Button>
                                    <Button
                                        asChild
                                        variant={"outline"}
                                        className="w-full mr-2 h-11 lg:w-44 text-PrimaryBlue"
                                    >
                                        <Link href={"/login"}>
                                            {t("auth.discover")}
                                        </Link>
                                    </Button>
                                </div>
                            </div>
                            <div className="lg:w-1/2 lg:relative">
                                <img
                                    src="LandingAssets/landingHero.png"
                                    alt="Landing hero"
                                    className="hidden rounded-lg lg:mr-14 lg:absolute lg:-top-6 lg:block ltr:ml-20"
                                    onError={(e) => {
                                        const target =
                                            e.target as HTMLImageElement;
                                        target.onerror = null; // Prevents infinite loop if placeholder fails
                                        target.src =
                                            "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                                    }}
                                />
                                <img
                                    src="LandingAssets/landingHero.png"
                                    alt="Landing hero"
                                    className="block lg:hidden"
                                    onError={(e) => {
                                        const target =
                                            e.target as HTMLImageElement;
                                        target.onerror = null; // Prevents infinite loop if placeholder fails
                                        target.src =
                                            "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                                        const ClassnameTarget =
                                            target.className;
                                        target.className =
                                            ClassnameTarget +
                                            " rounded-lg mb-6";
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className="hidden lg:static lg:block">
                            <img
                                src="LandingAssets/CompaniesBar.svg"
                                alt="Landing hero"
                                className="lg:mt-[5%] ltr:ml-[-40%] ltr:mt-[6%] ltr:bg-red-200 ltr:w-[100%]"
                            />
                        </div> */}
                    </motion.section>

                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="flex flex-col-reverse justify-center mt-8 lg:flex-row-reverse lg:gap-9 lg:px-16"
                    >
                        <div>
                            <div className="flex flex-col lg:mt-36 lg:w-[88%] gap-4">
                                <div className="">
                                    <h2 className="text-3xl font-bold lg:text-4xl">
                                        {t("Landing.whatDoWeServe")}
                                    </h2>
                                </div>
                                <div>
                                    <p className="text-lg">
                                        {t("Landing.whatDoWeServeDesc")}
                                    </p>
                                </div>
                                <Button
                                    asChild
                                    variant={"gradientBlue"}
                                    className="w-40 h-10"
                                >
                                    <Link href={"/register"}>
                                        {t("auth.startNow")}
                                    </Link>
                                </Button>
                            </div>
                        </div>
                        <div className="lg:w-[120%]">
                            <img
                                loading="lazy"
                                src="LandingAssets/landing3.png"
                                alt="Landing hero"
                                className=""
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null; // Prevents infinite loop if placeholder fails
                                    target.src =
                                        "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                                    const ClassnameTarget = target.className;
                                    target.className =
                                        ClassnameTarget + " rounded-lg mb-6";
                                }}
                            />
                        </div>
                    </motion.section>

                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <div className="text-2xl font-bold text-center">
                            <h2>{t("Landing.topCourses")}</h2>
                        </div>
                        <div>
                            <LandingCoursesCarousel courses={popularCourses} />
                        </div>
                    </motion.section>

                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.5, delay: 0.6 }}
                        className="flex flex-col-reverse gap-16 lg:flex-row lg:justify-between lg:gap-0 lg:px-7"
                    >
                        <div className="flex flex-col gap-7 lg:w-5/12">
                            <div>
                                <h2 className="text-xl font-bold lg:text-4xl">
                                    {t("Landing.benefits")}
                                </h2>
                            </div>
                            <div className="flex flex-col gap-5">
                                <div className="flex gap-4">
                                    <div>
                                        <LandingCertificate />
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <div>
                                            <h2 className="text-lg font-semibold">
                                                {t(
                                                    "Landing.onlineCertificates"
                                                )}
                                            </h2>
                                        </div>
                                        <div>
                                            <p className="text-PrimaryGray">
                                                {t(
                                                    "Landing.onlineCertificatesDesc"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <div>
                                        <LandingShortCourses />
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <div>
                                            <h2 className="text-lg font-semibold">
                                                {t("Landing.smallCourses")}
                                            </h2>
                                        </div>
                                        <div>
                                            <p className="text-PrimaryGray">
                                                {t("Landing.smallCoursesDesc")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <div>
                                        <LandingExperts />
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        <div>
                                            <h2 className="text-lg font-semibold">
                                                {t("Landing.learnWithExperts")}
                                            </h2>
                                        </div>
                                        <div>
                                            <p className="text-PrimaryGray">
                                                {t(
                                                    "Landing.learnWithExpertsDesc"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                loading="lazy"
                                src="LandingAssets/featuresSection.svg"
                                alt="Landing hero"
                                className=""
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null; // Prevents infinite loop if placeholder fails
                                    target.src =
                                        "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                                    const ClassnameTarget = target.className;
                                    target.className =
                                        ClassnameTarget + " rounded-lg mb-6";
                                }}
                            />
                        </div>
                    </motion.section>

                    <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.5, delay: 0.8 }}
                        className="flex flex-col gap-4"
                    >
                        <div className="flex flex-col gap-4">
                            <h2 className="text-2xl font-bold text-center">
                                {t("Landing.LandingFaqs.questsAndAnswers")}
                            </h2>
                            {/* <h2 className="text-center text-PrimaryGray lg:mx-96">
                                {t("Landing.onlineCertificatesDesc")}
                            </h2> */}
                        </div>
                        <div className="flex flex-col-reverse gap-5 lg:flex-row lg:justify-center lg:items-center">
                            <div className="lg:px-20">
                                <Accordion
                                    type="single"
                                    collapsible
                                    className="flex flex-col lg:flex-row lg:mt-10"
                                >
                                    <LandingFAQ
                                        activeItem={activeItem}
                                        handleToggle={handleToggle}
                                    />
                                </Accordion>
                            </div>
                            <div>
                                <img
                                    loading="lazy"
                                    src="LandingAssets/landingQA.png"
                                    alt="Landing hero"
                                    className=""
                                    onError={(e) => {
                                        const target =
                                            e.target as HTMLImageElement;
                                        target.onerror = null; // Prevents infinite loop if placeholder fails
                                        target.src =
                                            "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                                        const ClassnameTarget =
                                            target.className;
                                        target.className =
                                            ClassnameTarget +
                                            " rounded-lg my-5";
                                    }}
                                />
                            </div>
                        </div>
                    </motion.section>
                    <section className="flex flex-col gap-5 ">
                        <div className="text-2xl font-semibold text-center">
                            <h2>
                                {t("Landing.whatTheySaid")}{" "}
                                <b className="text-PrimaryBlue">
                                    {t("Landing.ourCourses")}
                                </b>
                            </h2>
                        </div>
                        <div>
                            <div className="absolute hidden lg:block left-96">
                                {" "}
                                <GreenEllipse />
                            </div>
                            <div className="absolute hidden lg:block right-96">
                                {" "}
                                <PinkEllipse />
                            </div>
                            <div className="relative mb-3 lg:mb-16">
                                <LandingFaqCarousel />
                            </div>
                        </div>
                    </section>
                </div>
            </DefaultLayout>
        </>
    );
}
