import React from "react";
import {
    frequentlyAskedQuestions,
    questionsRespones,
} from "@/lib/helpers/frequently-asked-questions";
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/Components/ui/Accordion";

interface AccordionItemsProps {
    activeItem: string | null;
    handleToggle: (value: string) => void;
}

const LandingFAQ: React.FC<AccordionItemsProps> = ({
    activeItem,
    handleToggle,
}) => {
    const data = frequentlyAskedQuestions.slice(0, 4);

    return (
        <div className="lg:w-[30rem]  ">
            <div className="grid grid-cols-1 gap-3">
                {data.map((item) => (
                    <AccordionItem
                        key={item.value}
                        value={item.value}
                        className=""
                    >
                        <AccordionTrigger
                            iconSrc="/AboutUsAssets/question.svg"
                            alternateIconSrc="/AboutUsAssets/answer.svg"
                            isOpen={activeItem === item.value}
                            onToggle={() => handleToggle(item.value)}
                        >
                            {item.question}
                        </AccordionTrigger>
                        <AccordionContent>{item.answer}</AccordionContent>
                    </AccordionItem>
                ))}
            </div>
        </div>
    );
};

export default LandingFAQ;
