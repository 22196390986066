import React from "react";

type TestimonialCardProps = {
    imageUrl: string;
    country: string;
    name: string;
    testimonial: string;
    isActive: boolean;
};

const TestimonialCard = ({
    imageUrl,
    country,
    name,
    testimonial,
    isActive,
}: TestimonialCardProps) => {
    return (
        <div
            className={`flex flex-col gap-4 mb-4 lg:mb-4 max-w-md rounded-3xl bg-white p-3 text-center mx-auto lg:mx-0`}
        >
            <div className="relative">
                <img
                    className="object-cover w-32 h-32 rounded-full absolute -top-10 lg:-top-9 left-1/2 transform -translate-x-1/2 shadow-2xl border-4 border-white"
                    src={imageUrl}
                    alt="User"
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null; // Prevents infinite loop if placeholder fails
                        target.src = "https://fakeimg.pl/600x400/e0e0e0/e0e0e0";
                    }}
                />
            </div>
            <div className="mt-20 lg:mt-24">
                <h3 className="text-sm md:text-base font-semibold text-green-500">
                    {country}
                </h3>
                <h4 className="text-md md:text-lg font-bold text-gray-800">
                    {name}
                </h4>
            </div>

            <p className="text-gray-500 text-xs md:text-sm">{testimonial}</p>
        </div>
    );
};

export default TestimonialCard;
