import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import { BlueEllipse } from "@/Components/ui/Icons";
import TestimonialCard from "./TestimonialCard";

const CustomDot = ({
    onClick,
    active,
}: {
    onClick: () => void;
    active: boolean;
}) => {
    return (
        <li
            className={`inline-block w-3 h-3 mt-10 rounded-full mx-1 transition-colors duration-500 ${
                active ? "bg-PrimaryBlue w-5 h-1" : "bg-blue-200 w-3 h-1"
            }`}
            onClick={onClick}
        />
    );
};

const LandingFaqCarousel = () => {
    const { t, i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [itemsPerScreen, setItemsPerScreen] = useState(1);
    const carouselRef = useRef<HTMLDivElement>(null);

    const reviews = useMemo(
        () => [
            {
                imageUrl:
                    "https://lh3.googleusercontent.com/d/18eYzvVfNigwdFADgyAWCjhzI6XKLJivV=s220?authuser=0",
                country: "السعودية",
                name: "احمد محمد ال عبود ",
                testimonial:
                    "جميل، مفيد، لازلت انتفع منه وانفع غيري منه. جزاكم الله خيرا.",
            },
            {
                imageUrl:
                    "https://lh3.googleusercontent.com/d/1nipJw1jVjW-xxwIwJ8Q3wUtv0GIfhz5i",
                country: "السعودية",
                name: "محمد عبدالله السعدان",
                testimonial: "البرنامج جيد وخاصة من جانب التأصيل والتنظير.",
            },
            {
                imageUrl:
                    "https://lh3.googleusercontent.com/d/1ff9PAxd1rkiXAA1mWMkIaGd_IAkmHZD1",
                country: "السعودية",
                name: "أسماء بنت فوزان المبارك",
                testimonial: "برنامج رائع استفدت منه كثيراً",
            },
            {
                imageUrl:
                    "https://lh3.googleusercontent.com/d/1I0tyPg2ez56TFic2abC7EQqxt92tnX4O",
                country: "السعودية",
                name: "ساره عابد الثمالي",
                testimonial: "مثري جدًا ومفيد",
            },
            {
                imageUrl:
                    "https://lh3.googleusercontent.com/d/1lVvyCryCDYP85rlUyAeUj3pVK1m4Hgsa",
                country: "السعودية",
                name: "عبدالعزيز بن عبدالله اليحيى",
                testimonial: "رائع",
            },
            // Add more reviews here
        ],
        []
    );

    const totalReviews = reviews.length;
    const infiniteReviews = useMemo(
        () => [reviews[totalReviews - 1], ...reviews, reviews[0]],
        [reviews, totalReviews]
    );

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setItemsPerScreen(3);
            } else {
                setItemsPerScreen(1);
            }
        };

        const debouncedHandleResize = debounce(handleResize, 200);

        window.addEventListener("resize", debouncedHandleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    useEffect(() => {
        if (currentIndex === 0) {
            setTimeout(() => setCurrentIndex(totalReviews), 0);
        } else if (currentIndex === totalReviews + 1) {
            setTimeout(() => setCurrentIndex(1), 0);
        }
    }, [currentIndex, totalReviews]);

    useEffect(() => {
        const translateValue =
            i18n.language === "ar"
                ? (100 / itemsPerScreen) * currentIndex
                : -(100 / itemsPerScreen) * currentIndex;

        if (carouselRef.current) {
            carouselRef.current.style.transform = `translateX(${translateValue}%)`;
        }
    }, [currentIndex, itemsPerScreen, i18n.language]);

    const handleDotClick = (index: number) => {
        setCurrentIndex(index + 1);
    };

    const renderItems = useMemo(() => {
        return infiniteReviews.map((review, index) => {
            const isActive =
                window.innerWidth >= 1024 &&
                index ===
                    (currentIndex + Math.floor(itemsPerScreen / 2)) %
                        infiniteReviews.length;
            return (
                <div
                    key={index}
                    className={`testimonial-item mt-10 md:mt-14 lg:mt-20 mb-0 md:mb-6 lg:mb-10 rounded-3xl bg-white ${
                        isActive
                            ? "scale-110 md:scale-115 lg:scale-125 z-20 shadow-2xl"
                            : "scale-100"
                    } transition-transform duration-300`}
                    style={{
                        flex: `0 0 ${100 / itemsPerScreen}%`,
                    }}
                >
                    <TestimonialCard
                        imageUrl={review.imageUrl}
                        country={review.country}
                        name={review.name}
                        testimonial={review.testimonial}
                        isActive={isActive}
                    />
                </div>
            );
        });
    }, [currentIndex, itemsPerScreen, infiniteReviews]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setCurrentIndex((prevIndex) => prevIndex - 1),
        onSwipedRight: () => setCurrentIndex((prevIndex) => prevIndex + 1),
        delta: 10,
        trackMouse: true,
    });

    return (
        <div
            {...swipeHandlers}
            className="relative overflow-hidden landing-faq-carousel"
        >
            <div
                className="flex transition-transform duration-500 carousel-container"
                ref={carouselRef}
                style={{
                    direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
            >
                {renderItems}
            </div>
            <ul className="relative flex justify-center mt-4 dots">
                {Array.from({ length: totalReviews }).map((_, index) => (
                    <CustomDot
                        key={index}
                        onClick={() => handleDotClick(index)}
                        active={index === currentIndex - 1}
                    />
                ))}
            </ul>
            <div className="absolute hidden lg:block bottom-8 left-96">
                {" "}
                <BlueEllipse />
            </div>
        </div>
    );
};

export default LandingFaqCarousel;

function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
